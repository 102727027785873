import * as React from "react";
import { IData as OutreachDetail } from "../../store/outreach/OutreachItem";
import { IData as  OutreachDoelgroep } from "../../store/outreach/OutreachDoelgroepen";
import { IData } from "../../store/outreach/OutreachItem";
import * as Amdd from "../../components/selectie/AllMedewerkersDropDown" ;
import {Alert, Button} from "react-bootstrap";
import {FetchState} from "../../entities/FetchState";
import { OutreachFullForm } from "../../organisms/OutreachForm/OutreachFullForm";
import { IData as OutreachSetting } from "../../store/outreach/outreachSettings";
import { IReportData, IReportDataObject } from "../../entities/reports/IReportData";
import { IMedewerkerList } from "../../store/selectie";
import { RouteComponentProps } from "react-router";
import { IApplicationState, RootActionCreators } from "../../store";
import { Dispatch } from "redux";
import { connect } from "react-redux";


interface IMatchParams {
    id: string;
}

interface IPropsFromParams extends RouteComponentProps<IMatchParams> { }

interface IPropsFromState {
    details: IReportDataObject<OutreachDetail>
    updater: IReportDataObject<OutreachDetail>
    deleter: IReportDataObject<OutreachDetail>
    doelgroepen: IReportData<OutreachDoelgroep>
    settings: IReportDataObject<OutreachSetting>
    medewerkes: IMedewerkerList
}


interface IPropsFromDispatch {
    onLoadData: (id: number) => void;
    onLoadDoelgroepen: () => void;
    onLoadSettings: () => void;
    onLoadMedewerkers: () => void;
    onReturnList: () => void;
    onEdit: (details: IData ) => void;
    onDelete: (id: number) => void;
}

export type AllProps = IPropsFromState & IPropsFromDispatch & IPropsFromParams

export const OutreachEditPage = (props: AllProps) => {

    const [details, setDetails] = React.useState(props.details.data);
    const [prevState, setPrevState] = React.useState(props.details.state);

    
    if (prevState !== props.details.state) {
        console.log("OutreachEditPage.tsx: state changed from " + prevState + " to " + props.details.state);
        setDetails(props.details.data); // update the state
        setPrevState(props.details.state); // when the external state changes
    }

    const id = +props.match.params.id;
    React.useEffect(() => {
        if (props.doelgroepen.state === FetchState.NotFetched) {
        
            props.onLoadDoelgroepen();
        }
        if (props.settings.state === FetchState.NotFetched) {
            props.onLoadSettings();
        }
        if (props.medewerkes.fetchState === FetchState.NotFetched) {
            props.onLoadMedewerkers();
        }

        if (id !== props.details.data.id && props.details.state !== FetchState.Busy && props.details.state !== FetchState.Error) {
            console.log("OutreachEditPage.tsx: onLoadData(" + id + ") is called because value is " + props.details.data.id + " " + details.id);
            props.onLoadData(id);
        }
    });

    const detailState = props.details.state;
    const doelgroepState = props.doelgroepen.state;
    const settingsState = props.settings.state;
    const medewerkersState = props.medewerkes.fetchState;

    let state = FetchState.Success
    if (detailState === FetchState.NotFetched || doelgroepState === FetchState.NotFetched || settingsState === FetchState.NotFetched || medewerkersState === FetchState.NotFetched) {
        state = FetchState.NotFetched;
    }
    if (detailState === FetchState.Busy || doelgroepState === FetchState.Busy || settingsState === FetchState.Busy || medewerkersState === FetchState.Busy) {
        state = FetchState.Busy
    }
    if (detailState === FetchState.Error || doelgroepState === FetchState.Error || settingsState === FetchState.Error || medewerkersState === FetchState.Error) {
        state = FetchState.Error;
    }

    if (props.updater.state === FetchState.Busy) {
        return (<div>

            <h1>Outreach: Opslaan</h1>
            <p>Bezig met opslaan van outreach...</p>

        </div>)
    }

    if (props.updater.state === FetchState.Error) {
        return (<div>

            <h1>Outreach: Opslaan</h1>
            <p>Bezig met opslaan van outreach...</p>
            <Alert variant="danger">Er is een fout opgetreden bij het opslaan van de outreach: {props.updater.errorMessage}</Alert>

        </div>)
    }

    if (props.deleter.state === FetchState.Busy) {
        return (<div>

            <h1>Outreach: Verwijderen</h1>
            <p>Bezig met verwijderen van outreach...</p>

        </div>)
    }

    if (props.deleter.state === FetchState.Error) {
        return (<div>

            <h1>Outreach: Verwijderen</h1>
            <p>Bezig met verwijderen van outreach...</p>
            <Alert variant="danger">Er is een fout opgetreden bij het verwijderen van de outreach: {props.deleter.errorMessage}</Alert>

        </div>)
    }

    switch (state) {
        case FetchState.NotFetched:
        case FetchState.Busy:
            return (<div>
                <h1>Outreach: Laden</h1>
                <p>
                    Bezig met laden van instellingen... {settingsState === FetchState.Success ? 'Klaar' : 'Even geduld aub'} <br/>
                    Bezig met laden van medewerkers... {medewerkersState === FetchState.Success ? 'Klaar' : 'Even geduld aub'} <br/>
                    Bezig met laden van doelgroepen... {doelgroepState === FetchState.Success ? 'Klaar' : 'Even geduld aub'} <br/>
                    Bezig met laden van outreach #{id}... {detailState === FetchState.Success ? 'Klaar' : 'Even geduld aub'} <br/>
                </p></div>)
        case FetchState.Error:

            return (<div>
                <h1>Outreach: Fout</h1>
                {detailState === FetchState.Error && <Alert variant="danger">An error has occured while getting details: {props.details.errorMessage} </Alert>}
                {doelgroepState === FetchState.Error && <Alert variant="danger">An error has occured while getting doelgroepen: {props.doelgroepen.errorMessage} </Alert>}
                {settingsState === FetchState.Error && <Alert variant="danger">An error has occured while getting settings: {props.settings.errorMessage} </Alert>}
                {medewerkersState === FetchState.Error && <Alert variant="danger">An error has occured while getting medewerkers: {props.medewerkes.errorMessage} </Alert>}
                <p>Fout bij met laden van gegevens, probeer opnieuw aub</p>
                <Button onClick={() => {props.onLoadData(id); props.onLoadDoelgroepen(); props.onLoadSettings(); props.onLoadMedewerkers()}}>Probeer opnieuw</Button>
            </div>)
        case FetchState.Success:
            if (details.datum === undefined) {
                console.log("OutreachEditPage.tsx: details is undefined");
                return (<h1>Outreach: Laden</h1>)
            } else if (details.locked) {
                return (<Alert variant="danger">Outreach {props.details.data.id} kan niet meer aangepast worden.</Alert>)
            } else {
                return (
                    <div>
                        <h1>Outreach: Detail {details.organisatie} - {details.datum.toLocaleDateString()}</h1>
                        <Button onClick={() => props.onLoadData(id)}>Vernieuw</Button>
                        <br /><br />

                        <OutreachFullForm settings={props.settings.data} details={details} detailUpdated={(det) => setDetails(det)} doelgroepen={props.doelgroepen.data} allMedewerkers={props.medewerkes.medewerkers} />

                        <Button onClick={() => props.onReturnList()}>Terug naar lijst</Button>
                        <Button className="btn btn-success" onClick={() => props.onEdit(details)}>Aanpassen</Button><br /><br />
                        <Button className="btn btn-warning" onClick={() => confirm("Bent u zeker dat u deze Outreach wenst te wissen?") && props.onDelete(details.id)}>Verwijderen</Button>

                    </div>
                )
            }
        default:
            return (<Alert variant="danger">This is an invalid loading state: {props.details.state}</Alert>)
    }
}

const mapStateToProps = (state: IApplicationState): IPropsFromState => ({
    details: state.outreach.outreachItem.Item,
    doelgroepen: state.outreach.outreachDoelgroepen.Items,
    settings: state.outreach.outreachSettings.Item,
    medewerkes: state.selectie.allMedewerkersNoAll,
    updater: state.outreach.outreachItem.UpdateItem,
    deleter: state.outreach.outreachItem.DeleteItem
});

const mapDispatchToProps = (dispatch: Dispatch): IPropsFromDispatch => ({
    onLoadData: (id: number) => dispatch(RootActionCreators.Outreach.outreachItem.FetchData(id)),
    onLoadDoelgroepen: () => dispatch(RootActionCreators.Outreach.outreachDoelgroepen.FetchData()),
    onLoadSettings: () => dispatch(RootActionCreators.Outreach.outreachSettings.FetchData()),
    onLoadMedewerkers: () => dispatch(RootActionCreators.Selectie.FetchAllMedewerkers()),
    onReturnList: () => dispatch(RootActionCreators.Navigate.ToOutreach()),
    onEdit: (details: IData) => dispatch(RootActionCreators.Outreach.outreachItem.UpdateData(details)),
    onDelete: (id: number) => dispatch(RootActionCreators.Outreach.outreachItem.DeleteData(id))
});

export default connect(mapStateToProps, mapDispatchToProps)(OutreachEditPage);