import * as React from "react";
import { IData as OutreachDoelgroep } from "src/store/outreach/OutreachDoelgroepen";
import { IData } from "src/store/outreach/OutreachItem";
import {Alert, Button} from "react-bootstrap";
import {FetchState} from "../../entities/FetchState";
import { OutreachFullForm } from "../../organisms/OutreachForm/OutreachFullForm";
import { IData as OutreachSettings } from "../../store/outreach/outreachSettings";
import { IReportData, IReportDataObject } from "../../entities/reports/IReportData";
import { IApplicationState, RootActionCreators } from "../../store";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { IMedewerkerList } from "../../store/selectie";

interface IPropsFromState {
    doelgroepen: IReportData<OutreachDoelgroep>,
    settings: IReportDataObject<OutreachSettings>,
    addDataState: IReportDataObject<IData>,
    medewerkers: IMedewerkerList
}


interface IPropsFromDispatch {
    onLoadDoelgroepen: () => void;
    onReturnList: () => void;
    onSave: (details: IData ) => void;
    onLoadSettings: () => void;
    onLoadMedewerkers: () => void;
}

export type AllProps = IPropsFromState & IPropsFromDispatch

export const OutreachNewPage: React.FC<AllProps> = (props: AllProps) => {

    const [details, setDetails] = React.useState<IData>({
        id: 0,
        aantalAanwezigNietProfessioneel: 0,
        aantalAanwezigProfessioneel: 0,
        datum: new Date(),
        contacts: 1,
        doelgroepen: [],
        doelgroepDetail: '',
        medewerkers: [],
        organisatie: '',
        savedInGir: false,
        thema: '',
        locked: false
    });

    if (props.doelgroepen.state === FetchState.NotFetched) {
        props.onLoadDoelgroepen();
    } 
    
    if (props.settings.state === FetchState.NotFetched) {
        props.onLoadSettings();
    }

    if (props.medewerkers.fetchState === FetchState.NotFetched) {
        props.onLoadMedewerkers();
    }

    const doelgroepState = props.doelgroepen.state;
    const settingsState = props.settings.state;
    const medewerkerState = props.medewerkers.fetchState;


    let state = FetchState.Success
    if (doelgroepState === FetchState.NotFetched || settingsState === FetchState.NotFetched || medewerkerState === FetchState.NotFetched) {
        state = FetchState.NotFetched;
    }
    if (doelgroepState === FetchState.Busy || settingsState === FetchState.Busy || medewerkerState == FetchState.Busy) {
        state = FetchState.Busy
    }
    if (doelgroepState === FetchState.Error || settingsState === FetchState.Error || medewerkerState == FetchState.Error) {
        state = FetchState.Error;
    }

    switch (state) {
        case FetchState.NotFetched:
        case FetchState.Busy:
            return (<div>
                <h1>Outreach: Laden</h1>
                <p>
                    Bezig met laden van instellingen... {settingsState === FetchState.Success ? 'Klaar' : 'Even geduld aub'} <br/>
                    Bezig met laden van doelgroepen... {doelgroepState === FetchState.Success ? 'Klaar' : 'Even geduld aub'} <br/>
                    Bezig met laden van medewerkers... {medewerkerState === FetchState.Success ? 'Klaar' : 'Even geduld aub'} <br/>
                </p></div>)
        case FetchState.Error:

            return (<div>
                <h1>Outreach: Fout</h1>
                {doelgroepState === FetchState.Error && <Alert variant="danger">An error has occured while getting doelgroepen: {props.doelgroepen.errorMessage} </Alert>}
                {settingsState === FetchState.Error && <Alert variant="danger">An error has occured while getting settings: {props.settings.errorMessage} </Alert>}
                {medewerkerState === FetchState.Error && <Alert variant="danger">An error has occured while getting medewerkers: {props.medewerkers.errorMessage} </Alert>}
                <p>Fout bij met laden van gegevens, probeer opnieuw aub</p>
                <Button onClick={() => {props.onLoadDoelgroepen(); props.onLoadSettings()}}>Probeer opnieuw</Button>
            </div>)
        case FetchState.Success:
            switch (props.addDataState.state) {
                case FetchState.Busy:
                    return (<div>
                        <h1>Outreach: Detail {details.organisatie} - {details.datum.toLocaleDateString()}</h1>
                        <p>Bezig met opslaan...</p>
                    </div>)

                default:
                    return (
                        <div>
                            <h1>Outreach: Detail {details.organisatie} - {details.datum.toLocaleDateString()}</h1>
                            {props.addDataState.state === FetchState.Error && <Alert variant="danger">Er was een fout tijdens het opslaan, probeer opnieuw aub:<br />{props.addDataState.errorMessage} </Alert>}
                            <br /><br />
                           
                            <OutreachFullForm settings={props.settings.data} details={details} detailUpdated={(det) => setDetails(det)} doelgroepen={props.doelgroepen.data} allMedewerkers={props.medewerkers.medewerkers} />
        
                            <Button onClick={() => props.onReturnList()}>Terug naar lijst</Button>
                            <Button className="btn btn-success" onClick={() => props.onSave(details)}>Opslaan</Button><br /><br />
                        </div>
                    )
            }
        default:
            return (<Alert variant="danger">This is an invalid loading state: {state}</Alert>)
    }
}


const mapStateToProps = (state: IApplicationState): IPropsFromState => ({
    medewerkers: state.selectie.allMedewerkersNoAll,
    doelgroepen: state.outreach.outreachDoelgroepen.Items,
    settings: state.outreach.outreachSettings.Item,
    addDataState: state.outreach.outreachItem.AddItem
});

const mapDispatchToProps = (dispatch: Dispatch): IPropsFromDispatch => ({
    onLoadMedewerkers: () => dispatch(RootActionCreators.Selectie.FetchAllMedewerkers()),
    onLoadDoelgroepen: () => dispatch(RootActionCreators.Outreach.outreachDoelgroepen.FetchData()),
    onLoadSettings: () => dispatch(RootActionCreators.Outreach.outreachSettings.FetchData()),
    onReturnList: () => dispatch(RootActionCreators.Navigate.ToOutreach()),
    onSave: (details: IData) => dispatch(RootActionCreators.Outreach.outreachItem.AddData(details))
});

export default connect(mapStateToProps, mapDispatchToProps)(OutreachNewPage);