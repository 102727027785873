import * as React from "react";
import { IData as OutreachDetail } from "../../store/outreach/OutreachItem";
import {Alert, Button} from "react-bootstrap";
import {FetchState} from "../../entities/FetchState";
import { IReportDataObject } from "../../entities/reports/IReportData";
import { IApplicationState, RootActionCreators } from "../../store";
import { Dispatch } from "redux";
import { push } from "connected-react-router";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";


interface IMatchParams {
    id: string;
}

interface IPropsFromParams extends RouteComponentProps<IMatchParams> { }

interface IPropsFromState {
    details: IReportDataObject<OutreachDetail>
}

interface IPropsFromDispatch {
    onLoadData: (id: number) => void;
    onReturnList: () => void;
    onEdit: (id: number) => void;
    onNew: () => void;
}

export type AllProps = IPropsFromState & IPropsFromDispatch & IPropsFromParams

export class OutreachDetailsPage extends React.Component<AllProps> {

    constructor(props: AllProps, context: any) {
        super(props, context);
    }

    public render() {
        const id = +this.props.match.params.id;

        if (id !== this.props.details.data.id && this.props.details.state !== FetchState.Busy && this.props.details.state !== FetchState.Error) {
            this.props.onLoadData(id);
        }

        switch (this.props.details.state) {
            case FetchState.NotFetched:
            case FetchState.Busy:
                return (<div><h1>Outreach: Laden</h1><p>Bezig met laden van outreach #{id}... Even geduld aub</p><br/><br/><br/><br/><p><small style={{color: '#ccc'}}>{this.props.details.state}</small></p></div>)
            case FetchState.Error:
                return (<div>
                    <h1>Outreach: Fout</h1>
                    <Alert variant="danger">An error has occured: {this.props.details.errorMessage}</Alert>
                    <p>Fout bij met laden van outreach #{id}, probeer opnieuw aub.</p>
                    <Button onClick={() => this.props.onLoadData(id)}>Probeer opnieuw</Button>
                </div>)
            case FetchState.Success:
                return (
                    <div>
                        <h1>Outreach: Detail {this.props.details.data.organisatie} - {this.props.details.data.datum.toLocaleDateString()}</h1>
                        <Button onClick={() => this.props.onLoadData(id)}>Vernieuw</Button>
                        <br /><br />
                        <table className={'table table-striped table-hover'}>
                            <tbody>
                                <tr><th scope={"row"}>Medewerkers:</th><td><ol>{this.props.details.data.medewerkers.map(m => <li key={m.medewerkerId}>{m.medewerker}</li>)}</ol></td></tr>
                                <tr><th scope={"row"}>Datum:</th><td>{this.props.details.data.datum.toLocaleDateString()}</td></tr>
                                <tr><th scope={"row"}>Contacten per medewerker:</th><td>{this.props.details.data.contacts}</td></tr>
                                <tr><th scope={"row"}>Organisatie:</th><td>{this.props.details.data.organisatie}</td></tr>
                                <tr><th scope={"row"}>Doelgroepen:</th><td>
                                    <ol>
                                        {this.props.details.data.doelgroepen.map(d => <li key={d.doelgroepId}>{d.doelgroep}</li>)}
                                    </ol>
                                    {this.props.details.data.doelgroepDetail !== '' && <div><strong>Doelgroep Detail:</strong> {this.props.details.data.doelgroepDetail}</div>}
                                    </td></tr>
                                <tr><th scope={"row"}>Thema:</th><td>{this.props.details.data.thema}</td></tr>
                                <tr><th scope={"row"}>Aantal aanwezigen:</th><td>Professioneel: {this.props.details.data.aantalAanwezigProfessioneel}<br />Niet-professioneel: {this.props.details.data.aantalAanwezigNietProfessioneel}<br />Totaal: {this.props.details.data.aantalAanwezigProfessioneel + this.props.details.data.aantalAanwezigNietProfessioneel}</td></tr>
                                <tr><th scope={"row"}>Geregistreerd in GIR:</th><td>{this.props.details.data.savedInGir ? 'Ja' : 'Nee'}</td></tr>
                            </tbody>
                        </table>


                        <Button onClick={() => this.props.onReturnList()}>Terug naar lijst</Button>
                        {this.props.details.data.locked == false && <Button onClick={() => this.props.onEdit(this.props.details.data.id)}>Aanpassen</Button> }
                        <Button onClick={() => this.props.onNew()}>Nieuw</Button>

                    </div>
                )
            default:
                return (<Alert variant="danger">This is an invalid loading state: {this.props.details.state}</Alert>)
        }
    }

}

/* Mapping the state to this page */

const mapStateToProps = (state: IApplicationState): IPropsFromState => ({
    details: state.outreach.outreachItem.Item
});

const mapDispatchToProps = (dispatch: Dispatch): IPropsFromDispatch => ({
    onLoadData: (id: number) => dispatch(RootActionCreators.Outreach.outreachItem.FetchData(id)),
    onReturnList: () => dispatch(RootActionCreators.Navigate.ToOutreach()),
    onEdit: (id: number) => dispatch(RootActionCreators.Navigate.ToOutreachEdit(id)),
    onNew: () => dispatch(RootActionCreators.Navigate.ToOutreachNew()),
});

export default connect(mapStateToProps, mapDispatchToProps)(OutreachDetailsPage); 